<template>
  <div class="mheader">
    <div class="header">
      <div @click="tohome('/')" class="headimage">
        <img src="@/assets/img/t01909d2956f8cc8fb8.png" alt="" />
      </div>
      <div class="right">
        <div><img src="@/assets/img/商城1.svg" alt="" /></div>
        <div><img src="@/assets/img/转发.svg" alt="" /></div>
        <div><img src="@/assets/img/个人中心.svg" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const tohome = (e) => {
  // console.log(e);
  router.push(e);
};
</script>

<style lang="scss" scoped>
.mheader {
  display: flex;
  align-items: center;
  justify-content: center;
  .header {
    padding: 63px 0px;
    max-width: 1280px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .right {
      display: flex;
      :nth-child(n + 1) {
        margin-left: 20px;
      }
      div {
        background: #999999;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        padding: 10px;
        box-sizing: border-box;
        &:hover{
          background: #333;
        }
        cursor: pointer;
        img {
          max-width: 100%;
          margin-left: 0px !important;
        }
      }
    }
    .headimage {
      cursor: pointer;
    }
  }
}
</style>
