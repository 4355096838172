<template>
  <div>
    <div class="sild"></div>
    <div class="mfooter">
      <div class="footer">
        <div class="totheimg">
          <div>
            <img src="@/assets/img/facebook.svg" alt="" />
          </div>
          <div><img src="@/assets/img/instagram.svg" alt="" /></div>
          <div><img src="@/assets/img/tiktok.svg" alt="" /></div>
          <div><img src="@/assets/img/youtube.svg" alt="" /></div>
          <!-- <div><img src="" alt="" /></div> -->
        </div>
        <div class="solid"></div>
        <div class="bottom">
          <div>Privacy Policy * Terms * Contact</div>
          <div>@2024 WorldSkills international</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.mfooter {
  background: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .footer {
    max-width: 1280px;
    width: 100%;
    padding: 24px 54px 30px;
    box-sizing: border-box;
    .solid {
      background: #585858;
      margin: 20px 0 10px;
      width: 100%;
      height: 2px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
    }
  }
}
.sild {
  width: 100%;
  height: 10px;
  background: #000;
}
.totheimg {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    background-color: #333333;
    padding: 5px 10px;
    border-radius: 50%;
    &:hover {
      background-color: #999;
    }
    cursor: pointer;
    img {
      max-width: 100%;
      margin-left: 0px !important;
    }
  }
  :nth-child(n + 1) {
    margin-left: 40px;
  }
}
</style>
