<template>
  <div class="sildebar">
    <div v-for="(item, index) in date.leftlist" :key="index" class="silde">
      {{ item }}
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
const date = reactive({
  leftlist: ["C&F", "M&E", "T&L", "C&B", "S&P", "I&C"],
});
</script>

<style lang="scss" scoped>
.sildebar {
  background: #333333;
  position: fixed;
  padding: 30px 20px;
  border-radius: 0px 16px 16px 0px;
  .silde {
    color: #999999;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 800;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
